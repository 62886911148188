
import { Vue } from 'vue-class-component';
import { useStore } from '@/store';
import app from '@/main';

export default class About extends Vue {
  public store = useStore();
  public blogshopDocument;
  public showBlogShop = false;

  // eslint-disable-next-line
  get user(): any {
    return this.store.state.user;
  }
  async created() {
    if (this.user) {
      const blogshopRef = await app.config.globalProperties.$firestore
        .collection('blogshops')
        .doc(this.user.uid)
        .get();
      if (blogshopRef && blogshopRef.exists) {
        this.blogshopDocument = blogshopRef.data();
        if (this.blogshopDocument.blogshopExists) {
          this.blogshopDocument.id = blogshopRef.id;
          this.showBlogShop = true;
        }
      }
    }
  }
}
